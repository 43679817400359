import { FC } from "react";
import Timeline, {
  DateHeader,
  Id,
  TimelineHeaders,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { ItemRenderer } from "./RowItem";
import "./index.css";
import { GroupItem } from "./GroupItem";

interface TimeLineProps {
  items?: any[];
  groups?: {
    id: string;
    title: string;
    bgColor: string;
  }[];
  categories?: {
    id: string;
    title: string;
    bgColor: string;
  }[];
  visibleTimes: [number, number];
  onDrag: (itemId: Id, dragTime: number, newGroupOrder: number) => void;
  onResize: (itemId: Id, time: number, edge: "left" | "right") => void;
  onItemClicked: (item: any) => void;
  onGroupClicked: (item: any) => void;
  onScrolling: (startTime: number, endTime: number) => void;
  isFullScreen?: boolean;
}
const TimeLineComponent: FC<TimeLineProps> = ({
  onDrag,
  onResize,
  onItemClicked,
  onGroupClicked,
  onScrolling,
  visibleTimes,
  items,
  groups,
  categories,
  isFullScreen,
}) => {
  return (
    <div
      className={
        isFullScreen
          ? "flex gap-3 flex-col w-full"
          : "flex gap-3 flex-col md:flex-row w-full"
      }
    >
      <div
        id="timeline"
        className={isFullScreen ? "w-full" : "md:w-3/4 w-full"}
      >
        <Timeline
          itemRenderer={(props) => (
            <ItemRenderer {...props} onItemClick={onItemClicked} />
          )}
          groupRenderer={(props) => (
            <GroupItem {...props} onGroupClicked={onGroupClicked} />
          )}
          sidebarContent={null}
          canMove={true}
          // minZoom={60 * 60 * 1000 * 6} //max zoom in is set to one day
          maxZoom={60 * 60 * 1000 * 24 * 20} // max zoom out is set to one month
          canChangeGroup={true}
          dragSnap={1000}
          canResize={"both"}
          groups={groups || []}
          itemTouchSendsClick={false}
          items={items || []}
          onTimeChange={(
            visibleTimeStart,
            visibleTimeEnd,
            updateScrollCanvas
          ) => {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
            onScrolling(visibleTimeStart, visibleTimeEnd);
          }}
          onItemMove={onDrag}
          onItemResize={onResize}
          visibleTimeStart={visibleTimes[0]}
          visibleTimeEnd={visibleTimes[1]}
          lineHeight={64}
          horizontalLineClassNamesForGroup={() => [
            "react-calendar-timeline__horizontal-line",
          ]}
        >
          <TimelineHeaders>
            <DateHeader
              labelFormat="HH:mm"
              unit="hour"
              intervalRenderer={(props) =>
                (props?.intervalContext.interval.labelWidth || 0) >= 30 ? (
                  <div
                    {...props?.getIntervalProps()}
                    style={{
                      ...props?.getIntervalProps().style,
                      backgroundColor: "white",
                      color: "black",
                      textAlign: "center",
                      height: "auto",
                    }}
                  >
                    {props?.intervalContext.intervalText}
                  </div>
                ) : (
                  <div
                    {...props?.getIntervalProps({
                      style: { backgroundColor: "#f5f5f5", height: 30 },
                    })}
                  />
                )
              }
            />
            <DateHeader
              unit="day"
              labelFormat="DD MMM YY"
              height={70}
              intervalRenderer={(props) => {
                return (
                  <div
                    {...props?.getIntervalProps()}
                    title={props?.intervalContext.intervalText}
                    style={{
                      ...props?.getIntervalProps().style,
                      backgroundColor: "white",
                      color: "black",
                      border: "solid 1px gray",
                      textAlign: "center",
                      height: "auto",
                    }}
                  >
                    {props?.intervalContext.intervalText}
                  </div>
                );
              }}
            />
          </TimelineHeaders>
        </Timeline>
      </div>
      <div
        className={
          isFullScreen ? "basis-full px-4" : "md:basis-1/4 basis-full px-4"
        }
      >
        <p className="text-lg m-0 mb-4">Category</p>
        <div
          className={
            isFullScreen
              ? "flex flex-col gap-4 flex-wrap overflow-auto max-h-32"
              : "flex flex-col gap-4 md:flex-nowrap flex-wrap overflow-auto max-h-20 md:max-h-[26rem]"
          }
        >
          {categories?.map((category) => (
            <span className="flex items-center gap-2" key={category.id}>
              <div
                className="h-4 w-4"
                style={{ backgroundColor: category.bgColor }}
              />
              <span>{category.title}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
export { TimeLineComponent };
