import {
  DownloadOutlined,
  HistoryOutlined,
  UploadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Empty,
  Tooltip,
  message,
  Select,
} from "antd";
import { TimeLineComponent, GoogleMap } from "components";
import { useState, useEffect, useMemo } from "react";
import { Id } from "react-calendar-timeline";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { WellItem } from "./types";
import { StepInForm } from "components/Step-In";
import { ScaleLoader } from "react-spinners";
import moment, { Moment } from "moment";
import { CSVLink } from "react-csv";
import { useSchedulerContext } from "Providers";
import HistoryDrawer, { TimelineItem } from "./HistoryDrawer";
import { useInsertStepIn } from "./queries";
import { useOptimzeHoists } from "./Upload/queries";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface ResponseInterface {
  items: WellItem[];
  groups: {
    id: string;
    title: string;
    bgColor: string;
  }[];
  categories: {
    id: string;
    title: string;
    bgColor: string;
  }[];
}
const hexTorgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgb(${r}, ${g}, ${b})`;
};
const HoistSequence = () => {
  const navigate = useNavigate();
  const [visibleTime, setVisibleTime] = useState<Moment[]>(() => [
    moment().add(-4, "days"),
    moment(4, "days"),
  ]);
  const [historyItems, setHistoryItems] = useState<TimelineItem[]>([]);
  const [formattedResponse, setFormattedResponse] = useState<ResponseInterface>(
    {} as ResponseInterface
  );
  const { response, fileNames } = useSchedulerContext();
  const { mutate, isLoading } = useInsertStepIn(
    fileNames?.[0],
    fileNames?.[1],
    fileNames?.[2],
    () => {
      message.success("Step-In added successfully !");
      setIsFormVisible(false);
    }
  );

  const canDownloadCSVData = useMemo(
    () =>
      !!(
        formattedResponse &&
        formattedResponse.items &&
        formattedResponse.items.length > 0
      ),
    [formattedResponse]
  );
  const initialStartTime = useMemo(
    () => response.data?.[0].start_at,
    [response]
  );

  const [optimizationObjective, setOptimizationObjective] = useState(
    response.optimizationObjective || "STANDARD"
  );
  const [isOPtimizeVisible, setIsOPtimizeVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const { isFetching: isReplanning } = useOptimzeHoists(
    isEnabled,
    () => {
      setIsEnabled(false);
      setIsOPtimizeVisible(false);
    },
    optimizationObjective,
    fileNames?.[0],
    fileNames?.[1],
    fileNames?.[2]
  );

  useEffect(() => {
    if (isEnabled) {
      setFormattedResponse({} as ResponseInterface);
      setPolylines([]);
    }
  }, [isEnabled]);

  const [form] = Form.useForm<{
    "proposal#": number;
    group: string;
    title: string;
    dates: Moment[];
    category: string;
    proposal_status: string;
  }>();
  const [polylines, setPolylines] = useState<
    {
      path: {
        lat: number;
        lng: number;
      }[];
      id: string;
      strokeColor: string;
      visible: boolean;
    }[]
  >([]);

  useEffect(() => {
    const groups = new Set<string>();
    const categories: {
      [key: string]: {
        id: string;
        title: string;
        bgColor: string;
        stackItems: boolean;
      };
    } = {};
    const items: WellItem[] = [];
    const hoistColor: { [key: string]: string } = {};
    response?.data?.forEach((item) => {
      groups.add(item.hoist_name);
      items.push({
        id: item.id,
        group: item.hoist_name,
        title: item.well_name,
        proposal: item["proposal#"],
        proposal_status: item.proposal_status,
        category: item.category,
        start_time: item.start_at,
        end_time: item.end_at,
        canMove: true,
        canChangeGroup: true,
        canResize: "both",
        bgColor: item.category_color_bgColor,
        selectedBgColor: item.category_color_selected,
        color: item.category_color,
        hoistBgColor: item.hoist_color,
      });
      if (hoistColor[item.hoist_name] == null) {
        hoistColor[item.hoist_name] = item.hoist_color;
      }
      if (categories[item.category] == null) {
        categories[item.category] = {
          id: item.category,
          title: item.category,
          stackItems: false,
          bgColor: item.category_color_bgColor,
        };
      }
    });
    setFormattedResponse({
      items,
      groups: [...groups].map((item, i) => ({
        id: item,
        title: item,
        bgColor: hoistColor[item],
        stackItems: true,
      })),
      categories: Object.values(categories),
    });
  }, [response]);
  useEffect(() => {
    const polylines = Object.values(
      response?.itemsGroupByHoist ? response?.itemsGroupByHoist : {}
    ).map((array) => ({
      path: array.map((item) => ({
        lat: item.lat,
        lng: item.lng,
      })),
      visible: true,
      id: array[0]["hoist_name"],
      strokeColor: array[0]["hoist_color"],
    }));
    setPolylines(polylines);
    setVisibleTime([
      moment(formattedResponse.items?.[0]?.start_time).add(-4, "day"),
      moment(formattedResponse.items?.[0]?.start_time).add(4, "day"),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const handleItemResize = (
    itemId: Id,
    time: number,
    edge: "left" | "right"
  ) => {
    const { items } = formattedResponse;
    setIsOPtimizeVisible(true);

    setFormattedResponse((state) => ({
      ...state,
      groups: [...(state?.groups || [])],
      categories: [...(state?.categories || [])],
      items: items?.map((item) =>
        item.id === itemId
          ? Object.assign({}, item, {
              start_time: edge === "left" ? time : item.start_time,
              end_time: edge === "left" ? item.end_time : time,
            })
          : item
      ),
    }));
  };

  const mapGroupItems = (
    item: WellItem
  ): { id: string; start_time: number; end_time: number; name: string } => ({
    id: item.id,
    start_time: item.start_time,
    end_time: item.end_time,
    name: item.title,
  });
  const handleItemMove = (
    itemId: Id,
    dragTime: number,
    newGroupOrder: number
  ) => {
    setIsOPtimizeVisible(true);
    const { items, groups } = formattedResponse;
    const draggedItem = items.find((item) => item.id === itemId);
    const group = groups[newGroupOrder];
    let newHistoryItem: TimelineItem = {} as TimelineItem;
    const oldItems = items.filter(
      (item) =>
        item.group === draggedItem?.group &&
        item.id !== itemId &&
        item.start_time >= draggedItem!.end_time
    );
    const isSameGroup = draggedItem?.group === group.id;
    const newEndTime =
      dragTime + (draggedItem!.end_time - draggedItem!.start_time);

    const sameGroupItems = (item: WellItem): boolean =>
      item.group === group.id &&
      item.id !== itemId &&
      moment(item.end_time).isAfter(dragTime) &&
      moment(item.start_time).isAfter(draggedItem?.start_time);
    const differentGroupItems = (item: WellItem): boolean =>
      item.group === group.id &&
      (item.start_time >= newEndTime ||
        (item.start_time <= newEndTime && item.end_time >= newEndTime) ||
        dragTime <= item.end_time);
    const groupItems = isSameGroup
      ? items.filter(sameGroupItems).map(mapGroupItems)
      : items.filter(differentGroupItems).map(mapGroupItems);
    const leftItems = isSameGroup
      ? items
          .filter(
            (item: WellItem): boolean =>
              item.group === group.id &&
              item.id !== itemId &&
              (moment(item.end_time).isBefore(newEndTime, "hour") ||
                (moment(item.end_time).isSameOrAfter(newEndTime) &&
                  moment(item.start_time).isSameOrBefore(newEndTime)))
          )
          .map(mapGroupItems)
      : [];
    const initialLeftItems = items.filter(
      (item) =>
        item.group === group.id &&
        item.id !== itemId &&
        moment(item.end_time).isSameOrBefore(draggedItem?.start_time)
    );
    const isLastItem = groupItems.length === 0;
    const isDraggingToRight = dragTime >= draggedItem!.start_time;
    const isFillingRightEmptySpace =
      dragTime + (draggedItem!.end_time - draggedItem!.start_time) <
      groupItems[0]?.start_time;
    const isFillingLeftEmptySpace =
      dragTime > leftItems[leftItems.length - 1]?.end_time;
    const isThereFirstItem =
      !isSameGroup && !isLastItem
        ? dragTime <= groupItems?.[0].end_time &&
          newEndTime >= groupItems?.[0].end_time
        : false;
    const isThereEmptySpace =
      !isSameGroup && newEndTime < groupItems[0].start_time;
    const dontAllowMoving = moment(initialStartTime).isAfter(
      leftItems[0]?.start_time -
        (leftItems[leftItems.length - 1]?.end_time - dragTime) -
        10000
    );
    setFormattedResponse((state) => ({
      ...state,
      items: items.map((item) => {
        if (isLastItem && item.id === itemId) {
          newHistoryItem = {
            startTime: item.start_time,
            newStartTime: dragTime,
            endTime: item.end_time,
            newEndTime: dragTime + (item.end_time - item.start_time),
            itemId: itemId,
            newGroup: group.id,
            originalGroup: draggedItem?.group || "",
            operationTime: moment().valueOf(),
            wellName: draggedItem?.title || "",
            stepIn: false,
          };
          return Object.assign({}, item, {
            start_time: dragTime,
            end_time: dragTime + (item.end_time - item.start_time),
            group: group.id,
          });
        }
        if (groupItems.some((groupItem) => item.id === groupItem.id)) {
          if (isSameGroup && isDraggingToRight && !isFillingRightEmptySpace) {
            return Object.assign({}, item, {
              start_time:
                item.start_time + (newEndTime - groupItems[0].start_time),
              end_time: item.end_time + (newEndTime - groupItems[0].start_time),
              group: group.id,
            });
          } else if (!isSameGroup) {
            const firstItem = groupItems[0];
            if (isThereEmptySpace) {
              if (item.id !== itemId) {
                return item;
              }
              return Object.assign({}, item, {
                start_time: dragTime,
                end_time: dragTime + (item.end_time - item.start_time),
                group: group.id,
              });
            } else if (
              dragTime <= item.end_time &&
              newEndTime >= item.end_time
            ) {
              return item;
            } else if (
              dragTime <= firstItem.end_time &&
              newEndTime >= firstItem.start_time
            ) {
              // We're moving an element to another group
              // We have two cases: either we're movning after an item or before an item
              return isThereFirstItem
                ? Object.assign({}, item, {
                    start_time: item.start_time + newEndTime - dragTime,
                    end_time: item.end_time + newEndTime - dragTime,
                    group: group.id,
                  })
                : Object.assign({}, item, {
                    start_time:
                      item.start_time + newEndTime - groupItems[0].start_time,
                    end_time:
                      item.end_time + newEndTime - groupItems[0].start_time,
                    group: group.id,
                  });
            }
          } else if (
            isSameGroup &&
            !isDraggingToRight &&
            isFillingLeftEmptySpace
          ) {
            const initalLeftItem =
              initialLeftItems[initialLeftItems.length - 1];
            if (dragTime >= initalLeftItem.end_time) {
              return Object.assign({}, item, {
                start_time:
                  item.start_time - (draggedItem.start_time - dragTime),
                end_time: item.end_time - (draggedItem.start_time - dragTime),
              });
            }
            //this is to close the gap if the item is moved from the center to the left
            else
              return Object.assign({}, item, {
                start_time:
                  item.start_time -
                  (draggedItem.end_time - draggedItem.start_time),
                end_time:
                  item.end_time -
                  (draggedItem.end_time - draggedItem.start_time),
              });
          }
        } else if (leftItems.some((groupItem) => item.id === groupItem.id)) {
          if (dontAllowMoving) {
            return item;
          }
          if (isSameGroup && !isDraggingToRight && !isFillingLeftEmptySpace) {
            const newItem = Object.assign({}, item, {
              start_time:
                item.start_time -
                (leftItems[leftItems.length - 1].end_time - dragTime) -
                10000,
              end_time:
                item.end_time -
                (leftItems[leftItems.length - 1].end_time - dragTime) -
                10000,
              group: group.id,
            });
            return newItem;
          }
        } else if (
          oldItems.some((groupItem) => item.id === groupItem.id) &&
          !isSameGroup &&
          !isFillingRightEmptySpace &&
          !isFillingLeftEmptySpace &&
          !isLastItem
        ) {
          //if we're leavning the old group we want to close the gap in the old group
          const timeToReduce = draggedItem!.end_time - draggedItem!.start_time;
          return Object.assign({}, item, {
            start_time: item.start_time - timeToReduce,
            end_time: item.end_time - timeToReduce,
          });
        }
        if (
          item.id === itemId &&
          !isSameGroup &&
          isThereFirstItem &&
          !isThereEmptySpace
        ) {
          newHistoryItem = {
            startTime: item.start_time,
            newStartTime: groupItems[0].end_time,
            endTime: item.end_time,
            newEndTime:
              item.end_time - item.start_time + groupItems[0].end_time,
            itemId: itemId,
            newGroup: group.id,
            originalGroup: draggedItem?.group || "",
            operationTime: moment().valueOf(),
            wellName: draggedItem?.title || "",
            stepIn: false,
          };
          return Object.assign({}, item, {
            start_time: groupItems[0].end_time,
            end_time: item.end_time - item.start_time + groupItems[0].end_time,
            group: group.id,
          });
        }
        // In case the user is trying to push items before the initial start time and the user is dragging to the left we should not allow that
        if (item.id === itemId) {
          if (dontAllowMoving && !isDraggingToRight && leftItems.length) {
            return item;
          } else {
            newHistoryItem = {
              startTime: item.start_time,
              newStartTime: groupItems[0].end_time,
              endTime: item.end_time,
              newEndTime:
                item.end_time - item.start_time + groupItems[0].end_time,
              itemId: itemId,
              newGroup: group.id,
              originalGroup: draggedItem?.group || "",
              operationTime: moment().valueOf(),
              wellName: draggedItem?.title || "",
              stepIn: false,
            };
            return Object.assign({}, item, {
              start_time: dragTime,
              end_time: dragTime + (item.end_time - item.start_time),
              group: group.id,
            });
          }
        } else {
          return item;
        }
      }),
    }));
    setHistoryItems((prevState) => [newHistoryItem, ...prevState]);
  };

  const handleInsertStepIn = async () => {
    const payload = await form.validateFields();
    const startTime = payload.dates[0].startOf("day").valueOf();
    const endTime = payload.dates[1].endOf("day").valueOf();
    const { items } = formattedResponse;
    const differentGroupItems = (item: WellItem): boolean =>
      item.group === payload.group &&
      (moment(endTime).isSameOrBefore(item.end_time) ||
        (moment(endTime).isSameOrAfter(item.end_time) &&
          moment(startTime).isSameOrBefore(item.end_time)));

    const groupItems = items.filter(differentGroupItems).map(mapGroupItems);

    const newWell = {
      id: formattedResponse.items.length + 1 + "",
      group: payload.group,
      title: payload.title,
      proposal: payload["proposal#"],
      proposal_status: payload.proposal_status,
      category: payload.category,
      start_time: payload.dates[0].startOf("day").valueOf(),
      end_time: payload.dates[1].endOf("day").valueOf(),
      canMove: true,
      canChangeGroup: true,
      canResize: "both" as const,
      bgColor: hexTorgb(response.categoryColors[payload.category]),
      selectedBgColor: hexTorgb(response.categoryColors[payload.category]),
      color: "",
      hoistBgColor: hexTorgb(response.hoistColors[payload.group]),
    };
    try {
      mutate({
        well_name: payload.title,
        action_code: 50,
        reason: "BPT",
        due_date: payload.dates[1].endOf("day").format("DD-MMM-YY"),
      });
      setFormattedResponse((prevState) => ({
        ...prevState,
        items: [
          ...items.map((item) => {
            if (
              moment(payload.dates[1].endOf("day").valueOf()).isSameOrBefore(
                groupItems[0].start_time
              )
            ) {
              return item;
            } else {
              if (groupItems.some((groupItem) => item.id === groupItem.id)) {
                const timeToAdd = endTime - groupItems[0].start_time;
                return Object.assign({}, item, {
                  start_time: item.start_time + timeToAdd,
                  end_time: item.end_time + timeToAdd,
                });
              }
            }
            return item;
          }),
          newWell,
        ],
      }));

      setHistoryItems((prevState) => [
        {
          startTime: payload.dates[0].startOf("day").valueOf(),
          endTime: payload.dates[1].endOf("day").valueOf(),
          itemId: formattedResponse.items.length + "",
          originalGroup: payload.group,
          operationTime: moment().valueOf(),
          wellName: payload.title,
          stepIn: true,
        },
        ...prevState,
      ]);
    } catch {
      message.error("There was an unexpected problem !");
    }
  };

  const handleCancelStepIn = () => {
    setIsFormVisible(false);
  };

  const handle = useFullScreenHandle();

  return (
    <div>
      <div className="flex justify-between">
        <span>
          <h1 className="text-4xl m-0">Hoist Sequence</h1>
          <p className="m-0 text-lg">
            Optimize hoists schedules and track their movement
          </p>
        </span>
        <div className="flex items-center w-2/5">
          <Button
            className="grow !text-white !bg-secondayColor mr-4 !font-bold !rounded-md"
            onClick={() => setIsFormVisible(true)}
          >
            Plan Step In
          </Button>
          <Button
            type="primary"
            icon={
              <UploadOutlined
                style={{ fontWeight: "bold", height: 18, width: 20 }}
              />
            }
            className="grow !rounded-md !inline-flex items-center justify-center !font-bold"
            onClick={() => navigate("upload")}
          >
            Upload Files
          </Button>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-end flex-wrap">
        <div>
          <RangePicker
            format={"DD/MM/YYYY"}
            value={[visibleTime[0], visibleTime[1]]}
            onChange={(values) => {
              const startTime = values?.[0] || moment();
              const endTime = values?.[1] || moment();
              setVisibleTime([startTime, endTime]);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-2">
        <Tooltip
          overlay={<span>Version history</span>}
          align={{ offset: [0, 10] }}
        >
          <Button
            className="!bg-transparent !border-none"
            shape="circle"
            icon={<HistoryOutlined />}
            onClick={() => setIsDrawerVisible((state) => !state)}
          />
        </Tooltip>
        <CSVLink
          onClick={(e, done) => {
            if (canDownloadCSVData) done(true);
            else done(false);
          }}
          asyncOnClick
          filename={`generated_sequence.csv`}
          data={
            canDownloadCSVData
              ? formattedResponse?.items?.map(
                  ({
                    id,
                    group,
                    title,
                    proposal_status,
                    category,
                    start_time,
                    end_time,
                  }) => ({
                    id,
                    hoist: group,
                    well: title,
                    proposal_status,
                    category,
                    start_time: moment(start_time).format("DD-MM-YYYY"),
                    end_time: moment(end_time).format("DD-MM-YYYY"),
                  })
                )
              : []
          }
        >
          <Button
            className="!bg-transparent !border-none"
            shape="circle"
            icon={<DownloadOutlined />}
            disabled={!canDownloadCSVData}
          />
        </CSVLink>
      </div>
      <div className="hoist-sequence-content h-full flex flex-col-reverse gap-4 lg:flex-row drop-shadow-xl time-line-container">
        <div className="flex flex-col bg-white lg:basis-1/3 rounded-md">
          <div className="flex justify-between">
            <span className="inline-flex items-center mx-4">
              <h3 className="text-secondayColor grow m-4">Hoists Movement</h3>
            </span>
            <button
              disabled={!canDownloadCSVData}
              className="rounded-md bg-pdoGreen text-white font-bold px-4 mx-4 my-2 disabled:bg-gray-300 disabled:cursor-not-allowed"
              onClick={() => {
                setPolylines((prevState) => {
                  return [
                    ...prevState.map((polyline) => ({
                      ...polyline,
                      visible: true,
                    })),
                  ];
                });
              }}
            >
              Show all Hoists
            </button>
          </div>
          <div className="flex grow">
            <GoogleMap polylines={polylines} />
          </div>
        </div>
        <FullScreen
          handle={handle}
          className="flex flex-col p-4 bg-white lg:basis-2/3 rounded-md overflow-x-auto"
        >
          <div className="flex flex-row">
            <h3 className="text-secondayColor grow m-0 self-center mr-4">
              Hoists Sequence
            </h3>
            <div className="flex flex-row grow justify-end">
              <p className="m-0 self-center mr-2">Optimized based on:</p>
              <Select
                className="!rounded-md"
                value={optimizationObjective}
                disabled={!canDownloadCSVData}
                onChange={(value) => {
                  setOptimizationObjective(value);
                  setIsOPtimizeVisible(true);
                }}
              >
                <Option value="STANDARD">Standard</Option>
                <Option value="PRIORITY_LIST">Priority list</Option>
                <Option value="MIN_HOIST_MOVEMENT">Min hoist movement</Option>
                <Option value="MIN_DEFERMENT">Min deferment</Option>
                <Option value="MAX_OIL_GAIN">Max oil gain</Option>
              </Select>
              <Button
                type="primary"
                className="!rounded-md !inline-flex !font-bold ml-4 !px-8"
                onClick={() => setIsEnabled(true)}
                disabled={!isOPtimizeVisible}
              >
                Re-Plan
              </Button>
              <Button
                className="!rounded-md ml-4"
                onClick={() => (handle.active ? handle.exit() : handle.enter())}
                icon={
                  handle.active ? (
                    <FullscreenExitOutlined />
                  ) : (
                    <FullscreenOutlined />
                  )
                }
              />
            </div>
          </div>
          <div className="flex grow items-center justify-center  my-4 mx-2">
            {canDownloadCSVData ? (
              <TimeLineComponent
                onScrolling={(startTime, endTime) => {
                  setVisibleTime([moment(startTime), moment(endTime)]);
                }}
                visibleTimes={[
                  visibleTime[0].valueOf(),
                  visibleTime[1].valueOf(),
                ]}
                categories={formattedResponse?.categories}
                groups={formattedResponse?.groups}
                items={formattedResponse?.items}
                onItemClicked={() => {
                  return;
                }}
                onGroupClicked={(item) => {
                  setPolylines((prevState) => {
                    return [
                      ...prevState.map((polyline) => {
                        if (polyline.id !== item.id) {
                          return { ...polyline, visible: false };
                        }
                        return { ...polyline, visible: true };
                      }),
                    ];
                  });
                }}
                onDrag={handleItemMove}
                onResize={handleItemResize}
                isFullScreen={handle.active}
              />
            ) : isReplanning ? (
              <div>
                <ScaleLoader
                  color="#4FAC5B"
                  height={70}
                  width={8}
                  margin={4}
                  radius={4}
                  loading={isReplanning}
                />
                <p className="font-semibold text-lg">Optimizing ...</p>
              </div>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <div>
                    <span className="font-bold text-base">
                      We’re sorry, but we don’t have any hoist sequence
                      optimized yet.
                    </span>
                    <br />
                    <span>
                      In order to optimize hoists, please click the “Upload
                      Files” button.
                    </span>
                    <br />
                    <span>
                      After uploading files, you’ll see your result here.
                    </span>
                  </div>
                }
              />
            )}
          </div>
        </FullScreen>
      </div>
      <HistoryDrawer
        drawerVisisble={isDrawerVisible}
        historyItems={historyItems}
        onClose={() => setIsDrawerVisible(false)}
      />
      <StepInForm
        loading={isLoading}
        categories={formattedResponse?.categories}
        groups={formattedResponse?.groups}
        isVisible={isFormVisible}
        handleInsert={handleInsertStepIn}
        handleCancel={handleCancelStepIn}
        form={form}
      />
    </div>
  );
};
export default HoistSequence;
