import { GoogleMap, LoadScript, Polyline } from "@react-google-maps/api";
interface MapProps {
  polylines: {
    path: { lat: number; lng: number }[];
    id: string;
    strokeColor: string;
    visible?: boolean;
  }[];
}

const mapsKey = "AIzaSyDKobY_p3qO_RUwV6BvuUVoZKUNRhvTXHU";

const Map = (props: MapProps) => (
  <LoadScript googleMapsApiKey={mapsKey}>
    <GoogleMap
      mapContainerStyle={{
        minHeight: "60vh",
        flexGrow: "1",
      }}
      center={{ lat: 18.14288861, lng: 55.24075944 }}
      zoom={10}
    >
      {props.polylines.map((polyline) => (
        <Polyline
          visible={polyline.visible}
          key={polyline.id}
          options={{
            strokeColor: polyline.strokeColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            visible: polyline.visible,
          }}
          path={polyline.path}
        />
      ))}
    </GoogleMap>
  </LoadScript>
);
export { Map as GoogleMap };
