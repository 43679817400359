import {
  DownloadOutlined,
  FilterOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Table, Tag, Tooltip, Modal, Spin } from "antd";
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import {
  comparaisonItem,
  historyItem,
  useHistoryComparison,
  useHistoryData,
} from "./queries";

type seriesItem = {
  name: string;
  data: { x: string; y: number }[];
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text: string) => {
      return <>#{text}</>;
    },
  },
  {
    title: "Date & Time",
    dataIndex: "datetime",
    key: "datetime",
    render: (text: string) => {
      return <>{text}</>;
    },
  },
  {
    title: "Hoist Movement (KM)",
    dataIndex: "hoistmovement",
    key: "hoistmovement",
    render: (text: string) => {
      return <>{Number(text).toFixed(3)}</>;
    },
  },
  {
    title: "Oil Gain (m3/d)",
    dataIndex: "oil_gain",
    key: "oil_gain",
    render: (text: string) => {
      return <>{Number(text).toFixed(3)}</>;
    },
  },
  {
    title: "Total Deferment (m3/d)",
    dataIndex: "total_deferment",
    key: "total_deferment",
    render: (text: string) => {
      return <>{Number(text).toFixed(3)}</>;
    },
  },
  {
    title: "Optimization Objective",
    dataIndex: "optimization_objective",
    key: "optimization_objective",
    render: (text: string) => {
      return <Tag>{text}</Tag>;
    },
  },
  {
    title: "Action",
    key: "action",
    render: (text: string, record: historyItem) => {
      return (
        <button
          className="text-pdoGreen cursor-pointer"
          onClick={() =>
            window.open(
              `https://hso-web.phaze.ro/api/sequence/download?filePath=${record.plan_link}`
            )
          }
        >
          Download Plan
        </button>
      );
    },
  },
  Table.SELECTION_COLUMN,
];

const ComparisonModal = (props: {
  isVisible: boolean;
  data?: comparaisonItem[];
  handleClose: () => void;
}) => {
  useEffect(() => {
    if (props.data !== undefined) {
      const seriesOilGain = props.data.map(
        ({ id, history, optimization_objective }) => ({
          name: `#${id.toString()}-${optimization_objective}`,
          data: history.map((item) => ({
            x: item.date,
            y: item.cum_oil_gain,
          })),
        })
      );
      setSeriesOilGain(seriesOilGain);

      const seriesTotalDeferment = props.data.map(
        ({ id, history, optimization_objective }) => ({
          name: `#${id.toString()}-${optimization_objective}`,
          data: history.map((item) => ({
            x: item.date,
            y: item.cum_deferment,
          })),
        })
      );
      setSeriesTotalDeferment(seriesTotalDeferment);
    }
  }, [props.data]);

  const [seriesOilGain, setSeriesOilGain] = useState<seriesItem[]>([]);
  const [seriesTotalDeferment, setSeriesTotalDeferment] = useState<
    seriesItem[]
  >([]);

  const optionsOG = {
    chart: {
      height: 350,
      type: "line" as const,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight" as const,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      title: {
        text: "Date",
      },
      type: "datetime" as const,
    },
    yaxis: {
      title: {
        text: "Oil Gain (m3/d)",
      },
    },
  };

  const optionsTD = {
    chart: {
      height: 350,
      type: "line" as const,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight" as const,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      title: {
        text: "Date",
      },
      type: "datetime" as const,
    },
    yaxis: {
      title: {
        text: "Total Deferment (m3/d)",
      },
    },
  };

  const isLoading = useMemo(() => {
    return seriesTotalDeferment.length <= 0 && seriesOilGain.length <= 0;
  }, [seriesOilGain, seriesTotalDeferment]);

  return (
    <Modal
      title="Compare Plans"
      visible={props.isVisible}
      onCancel={props.handleClose}
      width={1000}
      footer={
        <Button className="!px-8" type="primary" onClick={props.handleClose}>
          Close
        </Button>
      }
    >
      <Spin spinning={isLoading} tip="Loading...">
        <p>
          The following is a comparison of plans based on Oil Gain and Total
          Deferment.
        </p>
        <p>Oil Gain (m3/d)</p>
        <Chart
          options={optionsOG}
          series={seriesOilGain}
          type="line"
          height={300}
        />

        <p>Total Deferment (m3/d)</p>
        <Chart
          options={optionsTD}
          series={seriesTotalDeferment}
          type="line"
          height={300}
        />
      </Spin>
    </Modal>
  );
};

const TOOLTIP = "This feature is not functional yet";
const PlanningLogs = () => {
  const { data, isFetching } = useHistoryData();

  const [slectedRows, setSelectedRows] = useState<historyItem[]>([]);

  const [isComparing, setIsComparing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (isComparing && slectedRows.length > 1) setIsEnabled(true);
  }, [isComparing, slectedRows]);

  const [comparingData, setComparingData] = useState<comparaisonItem[]>([]);

  useHistoryComparison(
    slectedRows.map((row) => row.id.toString()),
    isEnabled,
    (data) => {
      setIsEnabled(false);
      setComparingData(data);
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: historyItem[]) => {
      if (selectedRows.length <= 5) setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: historyItem) => ({
      disabled:
        slectedRows.length >= 5 &&
        slectedRows.filter((row) => row.id === record.id).length === 0,
      name: record.id.toString(),
    }),
    selectedRowKeys: slectedRows.map((row) => row.id),
    columnTitle: (
      <Tooltip title="You can compare up to five plans in term of Oil Gain (m3/d) and Total Deferment (m3/d).">
        Compare Plan?
      </Tooltip>
    ),
  };

  const tagsList = useMemo(() => {
    const unselectRow = (key: number) => {
      setSelectedRows(slectedRows.filter((row) => row.id !== key));
    };
    return slectedRows.map((row) => (
      <Tag
        className="!inline-flex !items-center"
        key={row.id}
        closable
        onClose={() => unselectRow(row.id)}
      >
        {`#${row.id}`}
      </Tag>
    ));
  }, [slectedRows]);

  return (
    <div>
      <span>
        <h1 className="text-4xl m-0">Planning Logs</h1>
        <p className="m-0 text-lg">
          All re-plans on the hoist sequence page are tracked here. These plans
          listed below are for the entire plan.
        </p>
      </span>
      <Spin tip="loading..." spinning={isFetching}>
        {slectedRows.length > 0 && (
          <div className="mt-5 gap-4 flex-wrap px-4 py-2 drop-shadow-lg bg-white rounded-md flex flex-row items-center justify-between">
            <div>
              <h1 className="m-0 text-xl font-bold text-textColor">
                Compare Plans
              </h1>
              <p className="m-0 text-base text-textColor">
                You can select up to 5 plans.
              </p>
            </div>
            <div className="grow">{tagsList}</div>
            <div className="justify-between">
              <Button
                type="text"
                className="mx-2"
                onClick={() => setSelectedRows([])}
              >
                Clear All
              </Button>
              <Button
                type="primary"
                disabled={slectedRows.length <= 1}
                onClick={() => setIsComparing(true)}
              >
                ComparePlans
              </Button>
            </div>
          </div>
        )}
        <ComparisonModal
          isVisible={isComparing}
          data={comparingData}
          handleClose={() => {
            setComparingData([]);
            setIsComparing(false);
          }}
        />
        <div className="mt-4 px-4 py-2 drop-shadow-lg bg-white rounded-md">
          <Table
            title={() => (
              <div className="flex justify-end gap-2">
                <Tooltip placement="top" title={TOOLTIP}>
                  <Button icon={<SearchOutlined />} shape="circle" />
                </Tooltip>
                <Tooltip placement="top" title={TOOLTIP}>
                  <Button icon={<FilterOutlined />} shape="circle" />
                </Tooltip>
                <Tooltip placement="top" title={TOOLTIP}>
                  <Button icon={<PrinterOutlined />} shape="circle" />
                </Tooltip>
                <Tooltip placement="topRight" title={TOOLTIP}>
                  <Button icon={<DownloadOutlined />} shape="circle" />
                </Tooltip>
              </div>
            )}
            dataSource={data}
            rowKey={(record) => record.id}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{ total: data?.length, pageSize: 5 }}
            className="overflow-x-auto"
          />
        </div>
      </Spin>
    </div>
  );
};
export default PlanningLogs;
