import { useMutation, useQuery } from "react-query";
import { api } from "config/api";
import { AxiosError, AxiosResponse } from "axios";
import { SchedulerWellItem } from "types";
import { useSchedulerContext } from "Providers";
type Success = {
  msg: string;
  fileNames: string[];
  link: string;
};
type Error = {
  msg: string;
  link: string;
};

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};
const useUploadFiles = (
  onSuccess: (data: AxiosResponse<Success>) => void,
  onError: (data: AxiosError<Error>) => void
) => {
  const { setFileNames } = useSchedulerContext();
  return useMutation(
    (data: FormData) => api.post<Success>(`/sequence/verify`, data, config),
    {
      onError: (err: AxiosError<Error>) => onError(err),
      onSuccess: (data) => {
        setFileNames(data.data.fileNames);
        onSuccess(data);
      },
    }
  );
};

const useOptimzeHoists = (
  enabled: boolean,
  onSuccess: () => void,
  optimization_objective: string,
  nibras?: string,
  eWips?: string,
  opportunity?: string
) => {
  const context = useSchedulerContext();
  return useQuery(
    [`/sequence/optimize`, optimization_objective],
    () =>
      api.get<{
        items: SchedulerWellItem[];
        itemsGroupByHoist: { [key: string]: SchedulerWellItem[] };
        categoryColors: { [key: string]: string };
        hoistColors: { [key: string]: string };
        optimizationObjective: string;
      }>(`/sequence/optimize`, {
        params: {
          nibras_file: nibras,
          e_wips_file: eWips,
          opportunity_file: opportunity,
          optimization_objective: optimization_objective,
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled,
      onSuccess: (data) => {
        context?.setResponse({
          data: data.data.items,
          itemsGroupByHoist: data.data.itemsGroupByHoist,
          categoryColors: data.data.categoryColors,
          hoistColors: data.data.hoistColors,
          optimizationObjective: optimization_objective,
        });
        onSuccess();
      },
    }
  );
};
export { useUploadFiles };

export { useOptimzeHoists };
