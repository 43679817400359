import { useMutation } from "react-query";
import { api } from "config/api";
export const useInsertStepIn = (
  nibrasFile: string,
  eWipsFile: string,
  opportunityFile: string,
  onSuccess: () => void
) =>
  useMutation(
    (payload: {
      well_name: string;
      reason: string;
      due_date: string;
      action_code: number;
    }) =>
      api.post<
        | {
            file: string;
            msg: string;
          }
        | { err: string }
      >("/step-in", payload, {
        params: {
          nibras_file: nibrasFile,
          e_wips_file: eWipsFile,
          opportunity_file: opportunityFile,
        },
      }),
    {
      onSuccess,
    }
  );
