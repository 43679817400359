import { StatisticProps as AnStatisticProps } from "antd";
import { FC, ReactNode } from "react";
import clsx from "classnames";
interface StatisticProps
  extends Pick<AnStatisticProps, "value" | "suffix" | "prefix" | "title"> {
  subValue: AnStatisticProps["value"];
  date?: string;
  valueClassName?: string;
  unit?: ReactNode;
}
const Statistic: FC<StatisticProps> = ({
  value,
  prefix,
  suffix,
  subValue,
  date,
  title,
  valueClassName = "text-red-500",
  unit,
}) => {
  return (
    <div className="flex flex-col p-4 drop-shadow-md bg-white rounded-md grow gap-1">
      <p className="m-0 text-base text-secondayColor">{title}</p>
      <p className="m-0 text-xl text-textColor">
        {value} {unit}
      </p>
      <div className="flex items-center gap-2">
        <div
          className={clsx("inline-flex items-center gap-0.5", valueClassName)}
        >
          {prefix}
          <span>{subValue}</span>
          {suffix}
        </div>
        <span className="text-secondayColor">{date}</span>
      </div>
    </div>
  );
};
export { Statistic };
