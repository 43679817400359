import { FC } from "react";
import "./index.css";
interface TooltipOverlayProps {
  onClick?: () => void;
}
const TooltipOverlay: FC<TooltipOverlayProps> = ({ children, onClick }) => {
  return (
    <div
      className="flex items-center gap-4 bg-white rounded-md p-1"
      onClick={onClick}
    >
      <span>{children}</span>
    </div>
  );
};
export { TooltipOverlay };
