import { Component, ErrorInfo, ReactNode } from "react";
import { Result, Button } from "antd";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
  navigate: NavigateFunction;
}

interface State {
  hasError: boolean;
  err?: Error;
}
const WrappedErrorBoundery = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  return <ErrorBoundary {...props} navigate={navigate} />;
};
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(err: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, err };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen w-screen  grid items-center">
          <Result
            status="error"
            title="Something went seriously wrong"
            subTitle={this.state.err?.message}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => window.location.reload()}
              >
                Go Console
              </Button>,
            ]}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default WrappedErrorBoundery;
