import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";
import Logo from "assets/PDO_Logo_new.png";
import OpenedEmail from "assets/confirm_email.svg";
const ConfirmPasswordReset = () => {
  let navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <div className="flex flex-col items-center relative p-4">
      <button onClick={goBackHandler} className="absolute left-5 top-8">
        <ArrowLeftOutlined style={{ fontSize: "1.5rem", color: "#262626" }} />
      </button>
      <Image src={Logo} height={128} width={320} preview={false} />
      <div className=" max-h-[50vh] 2xl:max-h-full overflow-auto flex flex-col items-center w-full mt-4 gap-4">
        <Image src={OpenedEmail} height={128} width={320} preview={false} />
        <h3 className="text-left md:text-center text-3xl xl:text-4xl text-[#262626] font-semibold">
          Check Your Email
        </h3>
        <p>We’ve sent you instructions to recover your password</p>
      </div>
    </div>
  );
};
export default ConfirmPasswordReset;
