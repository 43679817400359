import {
  ArrowLeftOutlined,
  InboxOutlined,
  DownloadOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  Steps,
  Button,
  Upload as AntUpload,
  Form,
  Image,
  Dropdown,
  Menu,
} from "antd";
import { ScaleLoader } from "react-spinners";
import { useState, useMemo, useEffect } from "react";
import { useOptimzeHoists, useUploadFiles } from "./queries";
import ValidFile from "../../../../assets/valid.svg";
import InValidFile from "../../../../assets/cross.svg";
import { MenuInfo } from "rc-menu/lib/interface";
const messages = [
  "Uploading files",
  "Validating files",
  "Generating report",
  "Sending validation results",
];
const { Dragger } = AntUpload;
const { Step } = Steps;
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const UploadStep = (props: { form: any }) => (
  <Form form={props.form}>
    <h4 className="mt-6 text-lg">Steps</h4>
    <p className="m-0">
      Upload the files in the following order, to get the optimized sequence:
    </p>
    <ol className="list-decimal">
      <li className="ml-4 mt-4">
        <p className="m-0">Nibras (EC)*</p>
        <Form.Item
          name="nibras_file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please upload the corresponding file.",
            },
          ]}
        >
          <Dragger
            className="!mt-4"
            accept=".csv"
            beforeUpload={() => false}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single upload. Upload files in .csv only
            </p>
          </Dragger>
        </Form.Item>
      </li>
      <li className="ml-4 mt-4">
        <p className="m-0">E-wips*</p>
        <Form.Item
          name="e_wips_file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please upload the corresponding file.",
            },
          ]}
        >
          <Dragger
            className="!mt-4"
            accept=".csv"
            beforeUpload={() => false}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single upload. Upload files in .csv only
            </p>
          </Dragger>
        </Form.Item>
      </li>
      <li className="ml-4 mt-4">
        <p className="m-0">Opportunity Register*</p>
        <Form.Item
          name="opportunity_file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please upload the corresponding file.",
            },
          ]}
        >
          <Dragger
            className="!mt-4"
            accept=".csv"
            beforeUpload={() => false}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single upload. Upload files in .csv only
            </p>
          </Dragger>
        </Form.Item>
      </li>
    </ol>
  </Form>
);

const ValidationStep = (props: {
  isValid: boolean;
  isLoading: boolean;
  reportLink: string;
}) => {
  const [message, setMessage] = useState({ index: 0, message: messages[0] });
  useEffect(() => {
    const timer = setInterval(() => {
      setMessage((preveState) => ({
        index: preveState.index + 1,
        message: messages[(preveState.index + 1) % 4],
      }));
    }, 4000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="flex flex-col items-center m-8 text-center">
      <ScaleLoader
        color="#4FAC5B"
        height={70}
        width={8}
        margin={4}
        radius={4}
        loading={props.isLoading}
      />
      {props.isLoading && (
        <p className="font-bold text-xl">{message.message}</p>
      )}
      <div className={props.isLoading ? "hidden" : ""}>
        {props.isValid ? (
          <Image src={ValidFile} height={128} width={320} preview={false} />
        ) : (
          <Image src={InValidFile} height={128} width={320} preview={false} />
        )}

        <h1 className="text-2xl text-textColor font-bold m-2">
          {props.isValid ? "Success" : "Validation Failed"}
        </h1>
        <p>
          {" "}
          {props.isValid
            ? "All files are valid. "
            : "Something went wrong. Please refer to our report below to diagnose the issue. After fixing the issue, upload the files again to optimize your hoist sequence."}
        </p>
        <div>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              window.open(`https://hso-web.phaze.ro/api/sequence/download?filePath=${props.reportLink}`, "_blank");
            }}
            type="default"
            className="!font-semibold !inline-flex items-center justify-center"
          >
            Download Validation Report
          </Button>
        </div>
      </div>
    </div>
  );
};

const OptimizationStep = (props: {
  isLoading: boolean;
  isOptimized: boolean;
}) => (
  <div className="h-[30vh] grid place-items-center">
    <div className="inline-flex items-center flex-col mt-4">
      <ScaleLoader
        color="#4FAC5B"
        height={70}
        width={8}
        margin={4}
        radius={4}
        loading={props.isLoading}
      />
      <p className={props.isLoading ? "font-semibold text-lg" : "hidden"}>
        Optimizing ...
      </p>
      {!props.isLoading && (
        <div className="text-center">
          {props.isOptimized ? (
            <Image src={ValidFile} height={96} width={240} preview={false} />
          ) : (
            <Image src={InValidFile} height={96} width={240} preview={false} />
          )}

          <h1 className="text-xl text-textColor font-bold mt-4">
            {props.isOptimized
              ? "Sequence Results Have Been Optimized Successfully"
              : "Sequence Optimization Failed"}
          </h1>
        </div>
      )}
    </div>
  </div>
);

const Upload = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false);
  const [link, setLink] = useState("");
  const [optimizationObjective, setoptimizationObjective] =
    useState("STANDARD");

  const next = () => setCurrent(current + 1);
  const back = () => setCurrent(current - 1);
  const {
    mutate,
    data: fileList,
    isLoading: isValidLoading,
  } = useUploadFiles(
    (data) => {
      setLink(data.data.link);
    },
    (err) => {
      setLink(err.response?.data.link || "");
    }
  );

  const {
    data,
    refetch,
    isFetching: isLoading,
  } = useOptimzeHoists(
    isEnabled,
    () => {
      setIsEnabled(false);
    },
    optimizationObjective,
    fileList?.data.fileNames[0],
    fileList?.data.fileNames[1],
    fileList?.data.fileNames[2]
  );

  const isOptimized = useMemo(() => !!data && !isLoading, [data, isLoading]);

  const isValid = useMemo(
    () => !!fileList && !isValidLoading,
    [fileList, isValidLoading]
  );

  const isValidating = useMemo(
    () => !!!fileList && isValidLoading,
    [fileList, isValidLoading]
  );

  const [form] = Form.useForm();

  const upload = () => {
    form.submit();
    const nibras = form.getFieldValue("nibras_file")[0].originFileObj;
    const ewips = form.getFieldValue("e_wips_file")[0].originFileObj;
    const opportunity = form.getFieldValue("opportunity_file")[0].originFileObj;
    if (nibras && ewips && opportunity) {
      const formData = new FormData();
      formData.append("nibras_file", nibras);
      formData.append("e_wips_file", ewips);
      formData.append("opportunity_file", opportunity);
      mutate(formData);
      next();
    }
  };

  const onOptimize = (item: MenuInfo) => {
    setoptimizationObjective(item.key);
    setIsEnabled(true);
    next();
  };

  const menu = (
    <Menu onClick={(item) => onOptimize(item)}>
      <Menu.Item key="PRIORITY_LIST">Priority list</Menu.Item>
      <Menu.Item key="MIN_HOIST_MOVEMENT">Min hoist movement</Menu.Item>
      <Menu.Item key="MIN_DEFERMENT">Min deferment</Menu.Item>
      <Menu.Item key="MAX_OIL_GAIN">Max oil gain</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="flex items-start">
        <button className="h-6 w-6 mr-6 mt-1" onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 24, color: "#4FAC5B" }} />
        </button>
        <div>
          <h3 className="m-0 text-2xl text-textColor">Upload Files</h3>
          <p className="m-0 text-base text-textColor">
            Upload activities from here, the system will generate optimized
            results
          </p>
        </div>
      </div>
      <div className="drop-shadow-xl bg-white rounded-md mt-6 py-4">
        <div className="w-3/5 mx-auto my-0 p-4">
          <Steps current={current}>
            <Step key={"Upload"} title={"Uploading files"} />
            <Step key={"Validate"} title={"Validation"} />
            <Step key={"Optimize"} title={"Optimization"} />
          </Steps>
          <div>
            {current === 0 && <UploadStep form={form} />}
            {current === 1 && (
              <ValidationStep
                isValid={isValid}
                isLoading={isValidating}
                reportLink={link}
              />
            )}
            {current === 2 && (
              <OptimizationStep
                isOptimized={isOptimized}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className="mt-12 w-full">
            <div className="flex flex-row justify-evenly">
              {(current === 0 || (current === 1 && !isValidating)) && (
                <Button
                  onClick={() => navigate(-1)}
                  type="default"
                  className="!font-semibold !bg-secondayColor !text-white !px-8"
                >
                  Cancel
                </Button>
              )}
              {current === 0 && (
                <Button
                  type="primary"
                  className="!font-semibold !px-8"
                  onClick={upload}
                >
                  Upload Files
                </Button>
              )}
              {current === 1 && isValid && !isValidating && (
                <Dropdown.Button
                  overlay={menu}
                  icon={<RightOutlined />}
                  type="primary"
                  className="!font-semibold !px-8"
                  onClick={() => {
                    setIsEnabled(true);
                    next();
                  }}
                >
                  Optimize
                </Dropdown.Button>
              )}
              {current === 1 && !isValid && !isValidating && (
                <Button
                  type="primary"
                  className="!font-semibold !px-8"
                  onClick={() => {
                    back();
                  }}
                >
                  Re-upload Files
                </Button>
              )}

              {current === 2 && isOptimized && !isLoading && (
                <Button
                  type="primary"
                  className="!font-semibold !px-8"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Check Results
                </Button>
              )}

              {current === 2 && !isOptimized && !isLoading && (
                <Button
                  type="primary"
                  className="!font-semibold !px-8"
                  onClick={() => {
                    refetch();
                  }}
                >
                  Re-Optimize
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Upload;
