import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./pages/auth";
import Home from "./pages/home";
import Dashboard from "./pages/home/dashboard";
import HoistSequence from "./pages/home/hoist-sequence";
import Hoists from "./pages/home/hoists";
import Settings from "./pages/home/settings";
import PlanningLogs from "./pages/home/PlanningLogs";
import "./App.less";
import Login from "pages/auth/login";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ForgetPassword from "pages/auth/forget-password";
import ResetPassword from "pages/auth/reset-password";
import ConfirmPasswordReset from "pages/auth/confirm-password-reset";
import Upload from "pages/home/hoist-sequence/Upload";
import { AuthProvider } from "Providers";
import { ErrorBoundary } from "error";
import ReactGA from "react-ga4";
const queryClient = new QueryClient();
ReactGA.initialize("G-6N1CE0Y2LM");
ReactGA.send("pageview");
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Navigate replace to="/auth" />} />
              <Route path="/auth" element={<AuthLayout />}>
                <Route index element={<Navigate replace to="login" />} />
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgetPassword />} />
                <Route
                  path="confirm-reset-password"
                  element={<ConfirmPasswordReset />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
              <Route path="/home" element={<Home />}>
                <Route index element={<Navigate replace to="dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="hoists" element={<Hoists />} />
                <Route path="hoist-sequence">
                  <Route index element={<HoistSequence />} />
                  <Route path="upload" element={<Upload />} />
                </Route>
                <Route path="settings" element={<Settings />} />
                <Route path="planning-logs" element={<PlanningLogs />} />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                />
              </Route>
            </Routes>
          </AuthProvider>
        </ErrorBoundary>
      </BrowserRouter>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
