import {
  createContext,
  useContext,
  Dispatch,
  FC,
  useState,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
interface IAuthContext {
  setIsAuthentificated: Dispatch<React.SetStateAction<boolean>>;
  isAuthentificated: boolean;
}
const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const useAuthContext = () => useContext(AuthContext);
export const AuthProvider: FC = ({ children }) => {
  const [isAuthentificated, setIsAuthentificated] = useState(false);
  let location = useLocation();
  useEffect(() => {
    //log page views
    ReactGA.pageview(`page= ${location.pathname}`);
  }, [location]);
  return (
    <AuthContext.Provider value={{ isAuthentificated, setIsAuthentificated }}>
      {children}
    </AuthContext.Provider>
  );
};
