import { Drawer, Empty, Timeline } from "antd";
import { FC } from "react";
import moment from "moment";
interface DrawerProps {
  drawerVisisble: boolean;
  historyItems: TimelineItem[];
  onClose: () => void;
}
type TimelineItem = {
  itemId: string;
  wellName: string;
  newGroup?: string;
  originalGroup: string;
  startTime: number;
  endTime: number;
  newStartTime?: number;
  newEndTime?: number;
  operationTime: number;
  stepIn: boolean;
};
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const HistoryDrawer: FC<DrawerProps> = ({
  drawerVisisble,
  onClose,
  historyItems,
}) => {
  return (
    <Drawer
      onClose={onClose}
      maskClosable
      width={500}
      destroyOnClose
      placement="right"
      visible={drawerVisisble}
      title="Version History"
    >
      {historyItems.length ? (
        <Timeline mode="left">
          {historyItems.map((item) => (
            <Timeline.Item
              key={item.itemId}
              label={moment(item.operationTime).format("DD/MM/YYYY HH:mm")}
              color="green"
            >
              {item.stepIn
                ? `The new step-in ${item.wellName} was added from ${moment(
                    item.startTime
                  ).format(DATE_FORMAT)} to ${moment(item.endTime).format(
                    DATE_FORMAT
                  )}`
                : item.newGroup === item.originalGroup
                ? `${item.wellName} was moved from ${moment(
                    item.startTime
                  ).format(DATE_FORMAT)} and ${moment(item.endTime).format(
                    DATE_FORMAT
                  )} to ${moment(item.newStartTime).format(
                    DATE_FORMAT
                  )} and ${moment(item.newEndTime).format(DATE_FORMAT)}`
                : `${item.wellName} was moved to hoist ${
                    item.newGroup
                  } and from ${moment(item.startTime).format(
                    DATE_FORMAT
                  )} and ${moment(item.endTime).format(
                    DATE_FORMAT
                  )} to ${moment(item.newStartTime).format(
                    DATE_FORMAT
                  )} and ${moment(item.newEndTime).format(DATE_FORMAT)}`}
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <div className="flex items-center h-full justify-center">
          <Empty
            description={
              <>
                <p className="text-lg font-bold">No changes detected yet.</p>
                <p className="text-lg w-3/4 mx-auto">
                  Once a sequence is moved, you'll see all changes listed here.
                </p>
              </>
            }
          />
        </div>
      )}
    </Drawer>
  );
};
export default HistoryDrawer;
export type { TimelineItem };
