import faker from "faker";

export interface HoistDataType {
  hoist: string;
  area: string;
  cost: number;
  efficiency: number;
  status: string;
}

const data: HoistDataType[] = [
  {
    hoist: "WPH-1",
    area: "MML",
    cost: faker.datatype.number({ min: 100000, max: 2000000 }),
    efficiency: faker.datatype.number({ min: 0, max: 100 }),
    status: ["maintenance", "active"][
      faker.datatype.number({ min: 0, max: 1 })
    ],
  },
  {
    hoist: "WPH-27",
    area: "MML",
    cost: faker.datatype.number({ min: 100000, max: 2000000 }),
    efficiency: faker.datatype.number({ min: 0, max: 100 }),
    status: ["maintenance", "active"][
      faker.datatype.number({ min: 0, max: 1 })
    ],
  },
  {
    hoist: "WPH-54",
    area: "MML",
    cost: faker.datatype.number({ min: 100000, max: 2000000 }),
    efficiency: faker.datatype.number({ min: 0, max: 100 }),
    status: ["maintenance", "active"][
      faker.datatype.number({ min: 0, max: 1 })
    ],
  },
  {
    hoist: "WPH-56",
    area: "MML",
    cost: faker.datatype.number({ min: 100000, max: 2000000 }),
    efficiency: faker.datatype.number({ min: 0, max: 100 }),
    status: ["maintenance", "active"][
      faker.datatype.number({ min: 0, max: 1 })
    ],
  },
];

export default data;
