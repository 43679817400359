import { FC } from "react";
import clsx from "classnames";
interface InfoCardItemProps {
  color?: string;
  value: string;
  subValue?: string;
}
const InfoCardItem: FC<InfoCardItemProps> = ({
  value,
  color = "bg-green-600",
  subValue,
}) => {
  return (
    <div className="flex items-center">
      <div className="grow inline-flex items-center gap-2">
        <span className={clsx("h-2 w-2 rounded-full", color)} />
        <p className="text-textColor m-0">{value}</p>
      </div>
      {subValue && (
        <p title={subValue} className="truncate text-secondayColor m-0">
          {subValue}
        </p>
      )}
    </div>
  );
};
export { InfoCardItem };
