import { ArrowLeftOutlined } from "@ant-design/icons";
import { Form, Input, Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Logo from "assets/PDO_Logo_new.png";

const ResetPassword = () => {
  let navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  const onSubmitHandler = (values: { email: string }) => {};
  return (
    <div className="flex flex-col items-center relative">
      <button onClick={goBackHandler} className="absolute left-5 top-8">
        <ArrowLeftOutlined style={{ fontSize: "1.5rem", color: "#262626" }} />
      </button>
      <Image src={Logo} height={128} width={320} preview={false} />
      <div className="w-3/5">
        <h3 className="text-left md:text-center text-3xl xl:text-4xl text-[#262626] font-semibold">
          Reset password
        </h3>
        <p className="text-base text-center">Enter a new password</p>
        <Form
          onFinish={onSubmitHandler}
          name="forget_password"
          layout="vertical"
          requiredMark={false}
          colon
        >
          <Form.Item
            className="font-semibold"
            label="New password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please input your new password.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="font-semibold"
            label="Confirm password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm your new password.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="!rounded-sm !font-semibold"
            >
              Reset Password
            </Button>
            <div className="flex justify-center mt-4">
              {false && <BeatLoader size={20} margin={4} color="#4FAC5B" />}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default ResetPassword;
