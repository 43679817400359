import {
  DownloadOutlined,
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Table, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import data from "../../../data/hoists";
import { HoistDataType } from "../../../data/hoists";

const columns = [
  {
    title: "Hoist",
    dataIndex: "hoist",
    key: "hoist",
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
    render: (text: string) => {
      return <p>{text.toLocaleString()}</p>;
    },
  },
  {
    title: "Efficiency",
    dataIndex: "efficiency",
    key: "efficiency",
    render: (text: string) => {
      return <p>{text}%</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string, record: HoistDataType) => {
      const active = text === "active";
      return (
        <Tag color={active ? "success" : "warning"} className="!capitalize">
          {text}
        </Tag>
      );
    },
  },
  {
    title: "Action",
    render: () => {
      return <Link to={"#"}>Details</Link>;
    },
  },
];

const TOOLTIP = "This feature is not functional yet";
const Hoists = () => {
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h1 className="text-4xl m-0">Hoists</h1>
          <h4 className="text-lg m-0">Manage Hoists details</h4>
        </div>
        <Tooltip placement="top" title={TOOLTIP}>
          <Button
            size="large"
            type="primary"
            className="!inline-flex items-center !px-4 justify-center"
            icon={<PlusOutlined />}
          >
            Add Hoist
          </Button>
        </Tooltip>
      </div>
      <div className="mt-5 px-4 py-2 drop-shadow-xl bg-white rounded-md">
        <Table
          title={() => (
            <div className="flex justify-end gap-2">
              <Tooltip placement="top" title={TOOLTIP}>
                <Button icon={<SearchOutlined />} shape="circle" />
              </Tooltip>
              <Tooltip placement="top" title={TOOLTIP}>
                <Button icon={<FilterOutlined />} shape="circle" />
              </Tooltip>
              <Tooltip placement="top" title={TOOLTIP}>
                <Button icon={<PrinterOutlined />} shape="circle" />
              </Tooltip>
              <Tooltip placement="topRight" title={TOOLTIP}>
                <Button icon={<DownloadOutlined />} shape="circle" />
              </Tooltip>
            </div>
          )}
          dataSource={data}
          columns={columns}
          pagination={{ total: data.length, pageSize: 5 }}
          className="overflow-x-auto"
        />
      </div>
    </div>
  );
};
export default Hoists;
