import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, DatePicker, Row } from "antd";
import { Statistic } from "components";
import { InfoCardItem } from "components/info-card";
import Chart from "react-apexcharts";
import {
  activitiesPieChart,
  barChartOptions,
  donutChartOptions,
  lineChartOptions,
} from "./chart-options";
import { formatActivitiesTooltip } from "./tooltips";
import statistics from "../../../data/statistics";

const { RangePicker } = DatePicker;
const labels = [
  "Integrity Repair",
  "PCP",
  "Beam Pump",
  "ESP",
  "PCP upsize",
  "PCP Downsize",
  "Water Shutoff",
  "Reinstate",
];
const proposalLabels = [
  "Not Initiated",
  "Initiated",
  "Under Review",
  "Reviewed",
  "Approved",
];

const Dashboard = () => {
  return (
    <div>
      <div className="flex justify-between">
        <span>
          <h1 className="text-4xl m-0">Dashboard</h1>
          <p>All data displayed is based on the original data dump</p>
        </span>
        <RangePicker className="self-center" />
      </div>
      <Row className="mt-4 m-4 !gap-y-4" gutter={16}>
        <Col xs={24} md={12} lg={10}>
          <Row className="!gap-4 h-full">
            <Statistic
              title={statistics[0].title}
              value={statistics[0].value}
              prefix={<CaretDownOutlined />}
              suffix={statistics[0].suffix}
              date={statistics[0].date}
              subValue={statistics[0].subValue}
            />
            <Statistic
              title={statistics[1].title}
              value={statistics[1].value}
              prefix={<CaretUpOutlined />}
              suffix={statistics[1].suffix}
              date={statistics[1].date}
              subValue={statistics[1].subValue}
              unit={statistics[1].unit}
              valueClassName="text-green-500"
            />
          </Row>
        </Col>
        <Col xs={24} md={12} lg={10}>
          <Row className="!gap-4 h-full">
            <Statistic
              title={statistics[2].title}
              value={statistics[2].value}
              prefix={<CaretUpOutlined />}
              suffix={statistics[2].suffix}
              date={statistics[2].date}
              unit={statistics[2].unit}
              subValue={statistics[2].subValue}
              valueClassName="text-green-500"
            />
            <Statistic
              title={statistics[3].title}
              value={statistics[3].value}
              prefix={<CaretDownOutlined />}
              suffix={statistics[3].suffix}
              date={statistics[3].date}
              unit={statistics[3].unit}
              subValue={statistics[3].subValue}
            />
          </Row>
        </Col>
        <Col xs={24} lg={4}>
          <Row className="!gap-4 h-full">
            <Statistic
              title={statistics[4].title}
              value={statistics[4].value}
              prefix={<CaretDownOutlined />}
              suffix={statistics[4].suffix}
              date={statistics[4].date}
              unit={statistics[4].unit}
              subValue={statistics[4].subValue}
            />
          </Row>
        </Col>
      </Row>
      <Row className="mt-2 m-4 !gap-y-4" gutter={16}>
        <Col xs={24} lg={12} xl={10}>
          <div className="drop-shadow-md rounded-md bg-white p-4 h-full">
            <div className="flex justify-between">
              <p className="text-secondayColor text-lg">Activities per Type</p>
            </div>
            <div>
              <Chart
                options={{
                  ...activitiesPieChart(labels).options,
                  tooltip: {
                    custom: ({ dataPointIndex, seriesIndex }) =>
                      formatActivitiesTooltip({
                        count:
                          activitiesPieChart(labels).series[seriesIndex] * 2,
                        percentage:
                          activitiesPieChart(labels).series[seriesIndex],
                        title: labels[seriesIndex],
                      }),
                  },
                }}
                series={activitiesPieChart(labels).series}
                type="pie"
                height={300}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12} xl={10}>
          <div className="drop-shadow-md rounded-md bg-white p-4 h-full">
            <div className="flex justify-between">
              <p className="text-secondayColor text-lg">
                Proposals Status Count
              </p>
            </div>
            <div>
              <Chart
                options={{
                  ...donutChartOptions(proposalLabels).options,
                  tooltip: {
                    custom: ({ dataPointIndex, seriesIndex }) =>
                      formatActivitiesTooltip({
                        count:
                          donutChartOptions(proposalLabels).series[
                            seriesIndex
                          ] * 10,
                        percentage:
                          donutChartOptions(proposalLabels).series[seriesIndex],
                        title: proposalLabels[seriesIndex],
                      }),
                  },
                }}
                series={donutChartOptions(proposalLabels).series}
                type="donut"
                height={300}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} xl={4}>
          <div className="h-full flex flex-col sm:flex-row xl:flex-col gap-4">
            <div className="drop-shadow-md rounded-md p-4 bg-white sm:flex-auto">
              <p className="text-secondayColor">Scheduled Wells Today</p>
              <InfoCardItem value="TL-180" />
              <InfoCardItem value="RA-158" />
              <InfoCardItem value="TL-48" />
              <InfoCardItem value="DB-20" />
            </div>

            <div className="drop-shadow-md rounded-md p-4 bg-white sm:flex-auto">
              <p className="text-secondayColor">High Deferment Wells Today</p>
              <InfoCardItem
                value="ADB025"
                subValue="42 m3/d"
                color="bg-red-600"
              />
              <InfoCardItem
                value="MDNE045L"
                subValue="22 m3/d"
                color="bg-red-600"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2 m-4 !gap-y-4" gutter={16}>
        <Col xs={24} lg={12} xl={10}>
          <div className="drop-shadow-md rounded-md bg-white p-4">
            <div className="flex justify-between">
              <p className="text-secondayColor text-lg">
                Cumulative Hoist Movement (KM)
              </p>
            </div>
            <Chart
              options={{
                ...lineChartOptions().options,
              }}
              series={lineChartOptions().series}
              type="line"
              height={300}
            />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={10}>
          <div className="drop-shadow-md rounded-md bg-white p-4">
            <div className="flex justify-between">
              <p className="text-secondayColor text-lg">
                Restoration vs. Optimization
              </p>
            </div>
            <Chart
              options={{
                ...barChartOptions().options,
              }}
              series={barChartOptions().series}
              type="bar"
              height={300}
            />
          </div>
        </Col>
        <Col xs={24} xl={4}>
          <div className="h-full flex flex-col sm:flex-row xl:flex-col gap-4">
            <div className="drop-shadow-md rounded-md p-4 bg-white sm:flex-auto">
              <p className="text-secondayColor">High Integrity Wells Today</p>
              <InfoCardItem
                value="ADB025"
                subValue="2 days left"
                color="bg-red-600"
              />
              <InfoCardItem
                value="ADB125"
                subValue="4 days left"
                color="bg-red-600"
              />
              <InfoCardItem
                value="ADB075"
                subValue="6 days left"
                color="bg-red-600"
              />
              <InfoCardItem
                value="ADB065"
                subValue="2 days left"
                color="bg-red-600"
              />
              <InfoCardItem
                value="MDNE045L"
                subValue="8 days left"
                color="bg-red-600"
              />
            </div>
            <div className="drop-shadow-md p-4 bg-white rounded-md sm:flex-auto">
              <p className="text-secondayColor">Under Maintenance Hoists</p>
              <InfoCardItem
                value="Hoist-32"
                subValue="2 days left"
                color="bg-orange-500"
              />
              <InfoCardItem
                value="Hoist-65"
                subValue="6 days left"
                color="bg-orange-500"
              />
              <InfoCardItem
                value="Hoist-23"
                subValue="2 days left"
                color="bg-orange-500"
              />
              <InfoCardItem
                value="Hoist-29"
                subValue="8 days left"
                color="bg-orange-500"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Dashboard;
