import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { FC } from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import rgbHex from "rgb-hex";
import { TooltipOverlay } from "./TooltipOverlay";
import moment from "moment";
interface ItemRenderProps extends ReactCalendarItemRendererProps<any> {
  onItemClick: (item: any) => void;
}
const ItemRenderer: FC<ItemRenderProps> = ({
  onItemClick,
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const backgroundColor = itemContext.selected
    ? itemContext.dragging
      ? `#${rgbHex(item.bgColor)}4D`
      : `#${rgbHex(item.selectedBgColor)}99`
    : item.bgColor;
  return (
    <Tooltip
      getTooltipContainer={() => document.getElementById("timeline")!}
      key={item.id}
      placement="top"
      trigger={["hover"]}
      overlay={
        <TooltipOverlay>
          <div className="flex justify-between gap-4">
            <p className="m-0 font-bold">{item.title}</p>
            <div className="inline-flex items-center gap-1">
              <span
                className="h-3 w-3 rounded-full"
                style={{ backgroundColor: item.hoistBgColor }}
              />
              <p className="m-0 font-bold">{item.group}</p>
            </div>
          </div>
          <hr className="my-1" />
          <div>
            <p className="text-textColor m-0 font-medium">
              Proposal: {item.proposal}
            </p>
            <p className="text-textColor m-0 font-medium">
              Proposal status: {item.proposal_status}
            </p>
            <p className="text-textColor m-0 font-medium">
              Dates: {moment(item.start_time).format("DD/MM")} -{" "}
              {moment(item.end_time).format("DD/MM")}
            </p>
            <p className="text-textColor m-0 font-medium">
              Category: {item.category}
            </p>
          </div>
        </TooltipOverlay>
      }
    >
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: "white",
            fontWeight: "bold",
            borderRadius: 4,
            border: "none",
            textAlign: "center",
          },
          onMouseDown: () => onItemClick(item),
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            padding: "0 4px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    </Tooltip>
  );
};
export { ItemRenderer };
