import { FC } from "react";
import { ReactCalendarGroupRendererProps } from "react-calendar-timeline";
interface Group extends ReactCalendarGroupRendererProps {
  group: ReactCalendarGroupRendererProps["group"] & {
    bgColor: string;
  };
  onGroupClicked: (item: any) => void;
}
const GroupItem: FC<Group> = ({ group, onGroupClicked }) => {
  return (
    <div
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => onGroupClicked(group)}
    >
      <span
        className={`h-4 w-4 rounded-full`}
        style={{
          backgroundColor: group.bgColor,
        }}
      />
      <span className="title select-none">{group.title}</span>
    </div>
  );
};
export { GroupItem };
