import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL || "https://api-hso.phaze.ro";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

export { api };
