import { useAuthContext } from "Providers";
import { Navigate } from "react-router-dom";

export const PrivatePath = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthContext();

  if (!auth.isAuthentificated) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};
