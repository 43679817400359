import { Dispatch, createContext, useContext, useState, FC } from "react";
import { SchedulerWellItem } from "types";
type IResponseBody = {
  data: SchedulerWellItem[];
  itemsGroupByHoist: { [key: string]: SchedulerWellItem[] };
  categoryColors: { [key: string]: string };
  hoistColors: { [key: string]: string };
  optimizationObjective: string;
};
interface ISchedulerContext {
  setResponse: Dispatch<React.SetStateAction<IResponseBody>>;
  response: IResponseBody;
  setFileNames: Dispatch<React.SetStateAction<string[]>>;
  fileNames: string[];
}
const SchedulerContext = createContext<ISchedulerContext>(
  {} as ISchedulerContext
);
export const useSchedulerContext = () => useContext(SchedulerContext);

export const SchedulerProvider: FC = ({ children }) => {
  const [response, setResponse] = useState<IResponseBody>({} as IResponseBody);
  const [fileNames, setFileNames] = useState<string[]>([]);
  return (
    <SchedulerContext.Provider
      value={{ response, setResponse, fileNames, setFileNames }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};
