import { Outlet } from "react-router-dom";
import { Image } from "antd";
import Pattern from "assets/pattern.svg";
const AuthLayout = () => {
  return (
    <div className="bg-[#EEEEEE] h-screen flex items-center">
      <div className="bg-white rounded-md px-2 w-4/5 md:w-3/5 my-0 mx-auto drop-shadow-xl z-10">
        <Outlet />
      </div>
      <div className="absolute right-0 bottom-0">
        <Image
          wrapperClassName="!block"
          src={Pattern}
          preview={false}
          width={350}
        />
      </div>
    </div>
  );
};
export default AuthLayout;
