import { Outlet } from "react-router";
import { Button, Image, Layout, Menu, MenuItemProps } from "antd";
import PDOLogo from "assets/PDO_logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { FC } from "react";
import classNames from "classnames";
import { MoreOutlined } from "@ant-design/icons";
import { SchedulerProvider } from "Providers";
import { PrivatePath } from "components";
const { Content, Header } = Layout;
interface MenuItemExtendedProps extends MenuItemProps {
  path: string;
  pathName: string;
  active: boolean;
}
const MenuItem: FC<MenuItemExtendedProps> = ({
  path,
  pathName,
  active,
  ...props
}) => (
  <Menu.Item
    {...props}
    key={path}
    className={classNames({ "font-bold": true, "!bg-pdoYellow": active })}
  >
    <NavLink
      to={path}
      className={({ isActive }) => (isActive ? "!text-black" : "!text-white")}
    >
      {pathName}
    </NavLink>
  </Menu.Item>
);
const Home: FC = () => {
  const location = useLocation();
  return (
    <PrivatePath>
      <Layout>
        <Header className="h-full flex items-center w-full fixed  bg-white !p-0">
          <NavLink to="dashboard" className="h-full px-6 bg-white ">
            <Image src={PDOLogo} width={200} height={64} preview={false} />
          </NavLink>
          <div className="flex items-center justify-between w-full">
            <span className="w-full">
              <Menu
                theme="dark"
                mode="horizontal"
                className="!px-8 w-52 lg:w-11/12"
              >
                <MenuItem
                  pathName="Dashboard"
                  path="dashboard"
                  key="dashboard"
                  active={location.pathname.split("/")[2] === "dashboard"}
                />
                <MenuItem
                  key="hoist-sequence"
                  pathName="Hoist Sequence"
                  path="hoist-sequence"
                  active={location.pathname.split("/")[2] === "hoist-sequence"}
                />
                <MenuItem
                  pathName="Planning logs"
                  key="planning logs"
                  path="planning-logs"
                  active={location.pathname.split("/")[2] === "planning-logs"}
                />
                <MenuItem
                  pathName="Hoists"
                  path="hoists"
                  key="hoists"
                  active={location.pathname.split("/")[2] === "hoists"}
                />
              </Menu>
            </span>
            <div className="flex items-center gap-2 mx-6">
              <p className="m-0 font-bold text-white whitespace-nowrap text-clip">
                Hisham Al Barwani
              </p>
              <Button shape="circle" icon={<MoreOutlined />}></Button>
            </div>
          </div>
        </Header>
        <Content className="content mt-16 py-8 px-5">
          <SchedulerProvider>
            <Outlet />
          </SchedulerProvider>
        </Content>
      </Layout>
    </PrivatePath>
  );
};
export default Home;
