import { Props } from "react-apexcharts";
const activitiesPieChart = (labels: string[]) => ({
  series: [255, 1063, 641, 76, 454, 112, 270, 379],
  options: {
    chart: {
      width: 300,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
});
// 2/1/2022,250
// 2/15/2022,426
// 3/1/2022,670
// 3/15/2022,761
// 3/29/2022,830
// 4/15/2022,950
// 4/29/2022,1100
const lineChartOptions = () => ({
  series: [
    {
      name: "Cumulative Hoist movement (KM)",
      data: [250, 426, 670, 761, 830, 950, 1100],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line" as const,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight" as const,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "2/1/2022",
        "2/15/2022",
        "3/1/2022",
        "3/15/2022",
        "3/29/2022",
        "4/15/2022",
        "4/29/2022",
      ],
    },
  },
});
const donutChartOptions = (labels: string[]) => ({
  series: [24.9, 14.7, 10, 17.7, 32.6],
  options: {
    chart: {
      type: "donut" as const,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
});

const barChartOptions = () => ({
  series: [
    {
      data: [1785, 2846],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          console.log(chart, w, e);
        },
      },
    },
    colors: ["#A069F2", "#5FD0EF"],
    plotOptions: {
      bar: {
        columnWidth: "90%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [["Restoration"], ["Optimization"]],
      labels: {
        style: {
          colors: ["#A069F2", "#5FD0EF"],
          fontSize: "12px",
        },
      },
    },
  } as Props["options"],
});
export {
  activitiesPieChart,
  lineChartOptions,
  donutChartOptions,
  barChartOptions,
};
