import { api } from "config/api";
import { useAuthContext } from "Providers";
import { useMutation } from "react-query";

const useLogin = (onSuccess: () => void, onError: () => void) => {
  const { setIsAuthentificated } = useAuthContext();
  return useMutation(
    (data: { email: string; password: string }) =>
      api({
        method: "POST",
        url: "/users/login",
        data: JSON.stringify({ email: data.email, password: data.password }),
      }),
    {
      onSuccess: () => {
        setIsAuthentificated(true);
        onSuccess();
      },
      onError,
    }
  );
};
export { useLogin };
