import { FC } from "react";
import { Modal, Input, Select, Form, FormInstance, DatePicker } from "antd";
import { Moment } from "moment";
const { RangePicker } = DatePicker;
interface StepInFormInterface {
  title?: string;
  isVisible: boolean;
  groups?: {
    id: string;
    title: string;
    bgColor: string;
  }[];
  categories?: {
    id: string;
    title: string;
    bgColor: string;
  }[];
  okText?: string;
  cancelText?: string;
  handleInsert: () => void;
  handleCancel: () => void;
  form: FormInstance<{
    "proposal#": number;
    group: string;
    title: string;
    dates: Moment[];
    category: string;
    proposal_status: string;
  }>;
  loading: boolean;
}
const StepInForm: FC<StepInFormInterface> = ({
  title = "Plan Step-in",
  okText = "Insert",
  cancelText = "Cancel",
  isVisible,
  handleCancel,
  handleInsert,
  form,
  groups,
  categories,
  loading,
}) => {
  return (
    <Modal
      title={title}
      visible={isVisible}
      confirmLoading={loading}
      okText={okText}
      cancelText={cancelText}
      onOk={handleInsert}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form} name="step_in_form">
        <Form.Item
          name="proposal#"
          label="Proposal Number"
          rules={[{ required: true }]}
        >
          <Input placeholder="Proposal Number" />
        </Form.Item>
        <Form.Item
          name="group"
          label={"Hoist Name"}
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a Hoist" allowClear>
            {groups?.map(({ title }) => (
              <Select.Option value={title}>{title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="category"
          label={"Category"}
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a Category" allowClear>
            {categories?.map((category) => (
              <Select.Option value={category.title}>
                <span className="flex items-center gap-2" key={category.id}>
                  <div
                    className="h-4 w-4"
                    style={{ backgroundColor: category.bgColor }}
                  />
                  <span>{category.title}</span>
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="proposal_status"
          label={"Proposal Status"}
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a Proposal Status" allowClear>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="under proccessing">
              Under proccessing
            </Select.Option>
            <Select.Option value="inactive">Inactive</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="title" label="Well name" rules={[{ required: true }]}>
          <Input placeholder="Well" />
        </Form.Item>
        <Form.Item
          name="dates"
          label="Start and End Date"
          rules={[{ required: true }]}
        >
          <RangePicker className="!flex" format={"DD/MM/YYYY"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export { StepInForm };
