import { api } from "config/api";
import { useQuery } from "react-query";

export type comparaisonItem = {
  id: number;
  optimization_objective: string;
  history: {
    date: string;
    cum_deferment: number;
    cum_movement: number;
    cum_oil_gain: number;
  }[];
};

export type historyItem = {
  id: number;
  datetime: string;
  hoistmovement: number;
  oil_gain: number;
  total_deferment: number;
  plan_file_name: string;
  plan_link: string;
};

export type tmpHistoryItem = {
  key: string;
  datetime: string;
  hoistmovement: number;
  oil_gain: number;
  total_deferment: number;
  plan_file_name: string;
  plan_link: string;
};

export const useHistoryData = () =>
  useQuery(
    ["history", "items"],
    async () => {
      const response = await api.get<{
        items: historyItem[];
      }>("history");
      return response.data.items;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );

export const useHistoryComparison = (
  items: string[],
  enabled: boolean,
  onSuccess: (items: comparaisonItem[]) => void
) =>
  useQuery(
    ["history/compare", "items"],
    () =>
      api.get<{
        items: comparaisonItem[];
      }>("history/compare", { params: { items: items } }),
    {
      enabled,
      onSuccess: (data) => {
        onSuccess(data.data.items);
      },
    }
  );
