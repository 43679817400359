import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const statistics = [
  {
    title: "Number of Hoists' Jobs",
    value: 536,
    prefix: CaretUpOutlined,
    suffix: "%",
    date: "from last week",
    subValue: "2",
  },
  {
    title: "Percent of high priority Hoists' Jobs",
    value: 40.5,
    prefix: CaretDownOutlined,
    suffix: "%",
    unit: "%",
    date: "from last week",
    subValue: "4",
  },
  {
    title: "Total Gain",
    value: 6913.64,
    prefix: CaretUpOutlined,
    suffix: "%",
    unit: "m3/d",
    date: "Since last month",
    subValue: "18",
  },
  {
    title: "Total Deferment ",
    value: 5572.94,
    prefix: CaretDownOutlined,
    suffix: "%",
    unit: "m3/d",
    date: "Since last month",
    subValue: "10",
  },
  {
    title: "Total Hoist Movement",
    value: 1100,
    prefix: CaretDownOutlined,
    suffix: "%",
    unit: "Km",
    date: "Since last week",
    subValue: "9",
  },
];

export default statistics;
