import { Image, Form, Button, Input, message } from "antd";
import Logo from "assets/PDO_Logo_new.png";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useLogin } from "./queries";
const Login = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useLogin(
    () => {
      navigate("../../home", { replace: true });
    },
    () => {
      message.error("This user does not exist", 2);
    }
  );
  const onSubmitHandler = (values: { email: string; password: string }) => {
    mutate(values);
  };
  return (
    <div className="flex flex-col items-center">
      <Image src={Logo} height={128} width={320} preview={false} />
      <div className="w-3/5">
        <h3 className="text-center text-4xl text-[#262626] font-semibold">
          Sign in
        </h3>
        <p className="text-base">
          Enter your email address and password to continue
        </p>
        <Form
          onFinish={onSubmitHandler}
          name="sign_in"
          layout="vertical"
          requiredMark={false}
          colon
        >
          <Form.Item
            className="font-semibold"
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your email.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="font-semibold"
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password." }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="text-right font-semibold">
            <Link to="/auth/forgot-password">Forgot password?</Link>
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="!rounded-sm !font-semibold"
            >
              Sign in
            </Button>
            <div className="flex justify-center mt-4">
              {isLoading && <BeatLoader size={20} margin={4} color="#4FAC5B" />}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
